<template>
    <div class="report-container">
        <button type="submit" class="btn btn-primary" v-on:click="viewPDF">
            <i v-if="isLoading" class="fas fa-spinner fa-pulse" />
            PDF
        </button>
    </div>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'

export default {
    props: {
        jsonData: {
            type: Object,
            default: () => ({
                completed: [],
                incompleted: [],
                notVisited: [],
                company: [],
            }),
        },
        pdfHeader: {
            type: Array,
            default: () => [],
        },
        jsonTitle: {
            type: String,
            default: '',
        },
        filename: {
            type: String,
            default: '',
        },
        reportName: {
            type: String,
            default: '',
        },
        logoURL: {
            type: String,
            default: '',
        },
        startDate: {
            type: String,
            default: '',
        },
        endDate: {
            type: String,
            default: '',
        },
    },
    watch: {
        jsonData() {
            this.title = this.jsonTitle
            this.headers = this.pdfHeader
            this.name = this.filename + '.pdf'
            this.report = this.reportName
        },
    },
    data() {
        return {
            isLoading: false,
            title: '',
            headers: [],
            meta: [],
            name: '',
            report: '',
        }
    },
    methods: {
        async downloadPDF() {
            this.isLoading = true
            const doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' })
            let y = 10

            // Adicionar logo se existir
            if (this.logoURL) {
                const imgData = await this.loadImageWithWhiteBackground(this.logoURL)
                const imgProps = doc.getImageProperties(imgData)
                const imgWidth = 25 // Largura fixa para o logotipo
                const imgHeight = (imgProps.height * imgWidth) / imgProps.width // Altura proporcional
                const x = (doc.internal.pageSize.getWidth() - imgWidth) / 2 // Centralizar horizontalmente
                doc.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight)
                y += imgHeight + 10 // Adicionar margem após a imagem
            }

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(16)
            doc.text(this.title.toUpperCase(), 105, y, { align: 'center' })

            y += 20
            doc.setFontSize(10)
            doc.setFont('helvetica', 'normal')
            const startDate = this?.startDate ? moment(this.startDate).utc(false).format('DD/MM/YYYY') : ' '
            const endDate = this?.endDate ? moment(this.endDate).utc(false).format('DD/MM/YYYY') : ' '
            doc.text(`${this.$t('str.period')}: ${startDate} - ${endDate}`, 15, y)

            const categories = [
                { title: 'Rondas Concluídas', data: this.jsonData.completed, totalText: 'Total de rondas concluídas: ' },
                { title: 'Rondas Inconcluídas', data: this.jsonData.incompleted, totalText: 'Total de rondas inconcluídas: ' },
                { title: 'Rondas Não Realizadas', data: this.jsonData.notVisited, totalText: 'Total de rondas não realizadas: ' },
            ]

            const account = this.jsonData?.company && this.jsonData.company.length > 0 ? this.jsonData.company[0] : {}

            doc.setFontSize(12)
            doc.setFont('helvetica', 'bold')
            doc.text(account?.name ? account.name.toUpperCase() : ' ', 15, y)
            y += 6

            doc.setFontSize(10)
            doc.setFont('helvetica', 'normal')
            const addressParts = [account?.address?.address, account?.address?.number, account?.address?.neighborhood, account?.address?.city, account?.address?.state].filter(part => part)
            const address = addressParts.join(', ')
            doc.text(address, 15, y)
            y += 10

            let isFirstCategory = true

            for (const category of categories) {
                if (category.data.length === 0) continue

                if (!isFirstCategory) {
                    doc.addPage()
                    y = 25
                } else {
                    isFirstCategory = false
                }

                doc.setFontSize(12)
                doc.setFont('helvetica', 'bold')
                doc.setTextColor(50, 50, 50)
                doc.text(category.title, 15, y)
                const lineY = y + 1
                doc.setDrawColor(150, 150, 150)
                doc.line(15, lineY, doc.internal.pageSize.getWidth() - 15, lineY)
                y += 10

                let tableData = category.data.map(item => {
                    return [
                        item.name || 'N/A',
                        this.$t(item.status) || 'N/A',
                        this.$t(item.substatus) || 'N/A',
                        item.startDate || 'N/A',
                        item.endDate || 'N/A',
                        item.checkedPoints || 'N/A',
                        item.uncheckedPoints || 'N/A',
                    ]
                })

                doc.autoTable({
                    startY: y,
                    head: [['Nome', 'Status', 'Substatus', 'Data de Início', 'Data de Fim', 'Pontos Lidos', 'Pontos Não Lidos']],
                    body: tableData,
                    theme: 'striped',
                    styles: { font: 'helvetica', fontSize: 8, fontStyle: 'normal' },
                    headStyles: {
                        fillColor: [128, 128, 128],
                        textColor: 255,
                        fontStyle: 'bold',
                    },
                    columnStyles: {
                        0: { fontStyle: 'normal' },
                        1: { fontStyle: 'normal' },
                        2: { fontStyle: 'normal' },
                        3: { fontStyle: 'normal' },
                        4: { fontStyle: 'normal' },
                        5: { fontStyle: 'normal' },
                        6: { fontStyle: 'normal' },
                    },
                    didDrawCell: data => {
                        if (data.cell.section === 'body' && data.row.index === data.table.body.length - 1) {
                            y = data.cell.y + data.cell.height + 10
                        }
                    },
                })

                y += 10 // Spacing after each table
            }

            doc.setFontSize(10)
            doc.setFont('helvetica', 'normal')
            doc.text('Gerado em: ' + moment().format('DD/MM/YYYY HH:mm:ss'), 105, y, { align: 'left' })
            y += 10

            this.isLoading = false
            return doc.output('blob')
        },
        async viewPDF() {
            try {
                const pdfData = await this.downloadPDF()
                const pdfURL = URL.createObjectURL(pdfData)
                window.open(pdfURL, '_blank')
            } catch (error) {
                this.isLoading = false
                console.error('Error while generating PDF:', error)
            }
        },
        async loadImageWithWhiteBackground(url) {
            return new Promise((resolve, reject) => {
                const img = new Image()
                img.crossOrigin = 'Anonymous'
                img.onload = () => {
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    canvas.width = img.width
                    canvas.height = img.height

                    // Preencher o fundo com branco
                    ctx.fillStyle = '#FFFFFF'
                    ctx.fillRect(0, 0, canvas.width, canvas.height)

                    // Desenhar a imagem sobre o fundo branco
                    ctx.drawImage(img, 0, 0)
                    resolve(canvas.toDataURL('image/png'))
                }
                img.onerror = () => reject(new Error('Failed to load image'))
                img.src = url
            })
        },
    },
}
</script>
